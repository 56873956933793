// /var/www/thedailydialectics/src/functions/functions.tsx
import {fetchNavbarProps, fetchDirectoryList, fetchDirectoryPath, fetchDirectoryLink, fetchDirectoryLinks, fetchDirectoryPaths, fetchDirectoryCount, fetchMediaData, fetchMetaData, fetchImageComponent, fetchTweetData, fetchSocialPostUrl, renderSources,fetchPdfViewerInfo,fetchValidPdfs,fetchValidPathItems} from './tdd_fetch_functions';
export {fetchNavbarProps, fetchDirectoryList, fetchDirectoryPath, fetchDirectoryLink, fetchDirectoryLinks, fetchDirectoryPaths, fetchDirectoryCount, fetchMediaData, fetchMetaData, fetchImageComponent, fetchTweetData, fetchSocialPostUrl, renderSources,fetchPdfViewerInfo,fetchValidPdfs,fetchValidPathItems};
import {build_content} from './content_parser';
export {build_content};
import {tdd_dir, tdd_url, public_dir, src_dir, contents_dir, json_pages_dir, pages_dir, imgs_dir, pdfs_dir, tdd_imgs_url, tdd_pdfs_url, tdd_api_url, tdd_metadata_url, tdd_directory_lists_url, tdd_navbar_props_url, tdd_directory_path_url, tdd_directory_link_url, tdd_directory_count_url, tdd_source_data_url, tdd_valid_directory_links, tdd_valid_directory_paths, tdd_media_url, tdd_image_url, tdd_tweet_url, tdd_social_post_url,url_to_path,path_to_url,get_full_path,get_full_url} from './tdd_path_utils';
export {tdd_dir, tdd_url, public_dir, src_dir, contents_dir, json_pages_dir, pages_dir, imgs_dir, pdfs_dir, tdd_imgs_url, tdd_pdfs_url, tdd_api_url, tdd_metadata_url, tdd_directory_lists_url, tdd_navbar_props_url, tdd_directory_path_url, tdd_directory_link_url, tdd_directory_count_url, tdd_source_data_url, tdd_valid_directory_links, tdd_valid_directory_paths, tdd_media_url, tdd_image_url, tdd_tweet_url, tdd_social_post_url,url_to_path,path_to_url,get_full_path,get_full_url};
export function formatKeywords(keywords: string | string[] | undefined | null): string {
  if (!keywords) return '';
  
  const keywordList = Array.isArray(keywords) ? keywords : keywords.split(', ');
  return keywordList.join(', ');
}
export async function readBodyContent(content: string): Promise<any> {
  return new Promise<string>((resolve) => {
    setTimeout(() => resolve(content), 500);
  });
}

export function if_log(logsOn:boolean,msg:any[]){
  if (logsOn){
    console.log(...msg);
  }
}
