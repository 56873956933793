import {get_filename} from 'shared-functions'
import {fetchImageComponent} from './tdd_fetch_functions';
import {url_to_path,path_to_url,get_full_path,get_full_url} from './tdd_path_utils';
const IMG_IT_RE = /imgIt\(\s*"(.*?)"\s*,\s*"(.*?)"\s*,?\s*"?(\d+)?"?,?\s*"?(\d+)?"?\)/g;
const LYRICS_IT_RE = /lyricsIt\('(.+?)',\[\s*([\s\S]*?)\s*\]\)/g;
const IFRAME_IT_RE = /iframeIt\(\s*"(.*?)"\s*,\s*"(.*?)"\s*\)/g;
const SEOIMG_IT_RE = /SeoImgIt\(\s*"(.*?)"\s*(?:,\s*"(.*?)"\s*(?:,\s*"?(\d+)?"?\s*(?:,\s*"?(\d+)?"?\s*(?:,\s*"?((?:true|false))"?\s*)?)?)?)?\)/g;
const PAGEIMG_IT_RE = /PageImgIt\(\s*"(.*?)"\s*(?:,\s*"(.*?)"\s*(?:,\s*"?(\d+)?"?\s*(?:,\s*"?(\d+)?"?)?)?)?\)/g;
/**
 * Finds media metadata by `src` filename.
 */
export function getMediaData(src: string, media: any[]) {
    const filename = get_filename(src);
    return media.find(
      (item) =>
        item.basename === filename ||
        item.image_name === filename ||
        get_filename(item.og_url || "") === filename
    );
}
export function wrapSeoImage(component:any){
    const figureDivOpen = `<figure class="seo-image">`;
    const figureDivClose = `</figure>`;
    const aDivWhole =`${figureDivOpen}
    ${component}
    ${figureDivClose}
    `
    return aDivWhole;
}
export function wrapADiv(imageComponent:any,original:any=null,url:any=null,og_url:any=null){
    url = original || url || og_url
    const aDivOpen = `<a href="${url}" target="_blank" rel="noopener noreferrer">`
    const aDivClose = `</a>`
    const aDivWhole =`${aDivOpen}
    ${imageComponent}
    ${aDivClose}
    `
    return aDivWhole;
}
export function getImgComponent(
    url:any=null,
    width:any=null,
    height:any=null,
    title:any=null,
    alt:any=null,
    alt_2:any=null,
    caption:any=null,
    basename:any=null,
    background:any=null
){
    let style = ''
    if (background){
        let backgroundColor = background || 'white'
        if (typeof background === 'boolean' && background == true){
             backgroundColor = 'white'
        }
        style = `background-color: ${backgroundColor};`;
        
    }
    style = `${style} max-width: 100%; height: auto;`
    const imgComponent = `<img 
                            src="${url}"
                            width="${width}"
                            height="${height}"
                            alt="${alt || caption || basename}"
                            title="${title || alt_2 || basename}"
                            loading="lazy"
                            style= ${style}
                        />`
    return imgComponent;
}
export function iframeItParse(title: string, link: string) {
    return `<div class="iframe-container"><center>${title}<iframe width="560" height="315" src="${link}" frameborder="0" allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe></center></div>`;
}
export function iframeIt(content:any,meta?:any, filename?:any){
    content = content.replace(IFRAME_IT_RE,
        (_: string, title: string, link: string) => iframeItParse(title, link)
        )
    return content
}

export function lyricsItParse(title: string, lyrics_ls: string[]) {
    let lyrics_str = `<div class="lyrics-container"><h4>Lyrics: ${title}</h4><div class="lyrics-content">`;
    for (let i = 0; i < lyrics_ls.length; i++) {
      lyrics_str += `<p><strong>Verse ${i + 1}:</strong><br>`;
      // Ensure each line of lyrics appears separately using `<br>`
      lyrics_str += lyrics_ls[i]
        .split('\n') // Split on newlines
        .map(line => line.trim()) // Trim whitespace
        .filter(line => line.length > 0) // Remove empty lines
        .join('<br>'); // Join with <br> for proper new-line rendering
  
      lyrics_str += `</p>`;
    }
    lyrics_str += '</div></div>';
    return lyrics_str;
}

export function lyricsIt(content:any,meta?:any, filename?:any){
    content = content.replace(
        LYRICS_IT_RE,
        (_: string, title: string, lyricsRaw: string) => {
        const lyricsArray = lyricsRaw
            .split(/,(?=\s*`)/)
            .map((line) => line.trim().replace(/^`|`$/g, ""));
        return lyricsItParse(title, lyricsArray);
        }
    );
    return content
}

export function imgIt(content:any,meta:any, filename?:any){
    content = content.replace(
        IMG_IT_RE,
        (_: string, filename: string, jsonPath: string, width: string | undefined, height: string | undefined) => {
            const media = meta.media;
            const mediaData = getMediaData(filename, media);
            if (mediaData) {
                const imgWidth = width ? parseInt(width, 10) : "auto";
                const imgHeight = height ? parseInt(height, 10) : "auto";
                const url = mediaData.url;
                const alt = mediaData.alt;
                const alt_2 = meta.alt;
                const caption = mediaData.caption;
                const basename = mediaData.basename;
                const title = mediaData.title;
                const original = mediaData.original;
                const og_url = mediaData.og_url;
                const ImgComponent = getImgComponent(
                    url,
                    imgWidth,
                    imgHeight,
                    title,
                    alt,
                    alt_2,
                    caption,
                    basename
                );
                const aDiv = wrapADiv(ImgComponent,original,url,og_url);
                const seoImage = wrapSeoImage(aDiv);
                return seoImage;
            }

            return `<img src="${filename}" alt="Image not found" width="800" height="600" loading="lazy" />`;
        }
    );
    return content
}
export function pageImgIt(content:any,meta:any, filename?:any){
    content = content.replace(
        PAGEIMG_IT_RE,
        (_: string, directory: string, width?: string, height?: string, background?: string) => {
            const media = meta.media;
            const mediaData = getMediaData(filename, media);
            if (mediaData) {
                const imgWidth = width ? parseInt(width, 10) : "auto";
                const imgHeight = height ? parseInt(height, 10) : "auto";
                const url = mediaData.url;
                const alt = mediaData.alt;
                const alt_2 = meta.alt;
                const caption = mediaData.caption;
                const basename = mediaData.basename;
                const title = mediaData.title;
                const original = mediaData.original;
                const og_url = mediaData.og_url;
                const ImgComponent = getImgComponent(
                    url,
                    imgWidth,
                    imgHeight,
                    title,
                    alt,
                    alt_2,
                    caption,
                    basename
                );
                const aDiv = wrapADiv(ImgComponent,original,url,og_url);
                const seoImage = wrapSeoImage(aDiv);
                return seoImage;
            }

            return `<img src="${filename}" alt="Image not found" width="800" height="600" loading="lazy" />`;
        }
    );
    return content
}

async function asyncReplace(
    str: string,
    regex: RegExp,
    callback: (...args: any[]) => Promise<string>
  ): Promise<string> {
    // Collect all matches
    const matches: { match: string; index: number; groups: string[] }[] = [];
    let match: RegExpExecArray | null;
  
    // Reset the regex's lastIndex to ensure it starts from the beginning
    regex.lastIndex = 0;
  
    while ((match = regex.exec(str)) !== null) {
      matches.push({
        match: match[0],
        index: match.index,
        groups: match.slice(1),
      });
    }
  
    // Process each match asynchronously
    let result = str;
    let offset = 0; // Track the offset due to replacements changing the string length
  
    for (const { match, index, groups } of matches) {
      try {
        // Await the callback to get the replacement string
        const replacement = await callback(...groups, match, index, str);
        // Calculate the new position after previous replacements
        const start = index + offset;
        const end = start + match.length;
        // Replace the match with the resolved replacement
        result = result.slice(0, start) + replacement + result.slice(end);
        // Update the offset based on the difference in length
        offset += replacement.length - match.length;
      } catch (error) {
        console.error(`Error processing async replacement at index ${index}: ${error}`);
        // Skip the replacement and continue with the next match
      }
    }
  
    return result;
  }



export async function seoImgIt(content:any,meta:any, filename?:any){
    content = await asyncReplace(
        content,
        SEOIMG_IT_RE,
        async (
          directory: string,
          width?: string,
          height?: string,
          background?: string,
          captionIt?: string
        ) => {
        const caption = captionIt === undefined ? null : captionIt === "true";
        try {
            const htmlComponent = await fetchImageComponent(
                directory,
                width || '100%',
                height || 'auto',
                background || 'white',
                meta,
                caption
            );
            return htmlComponent;
        } catch (error) {
            console.error(`Error processing SeoImgIt for ${directory}: ${error}`); 
            const h3Open = `<h3 style="width: ${width || '800px'}; height: ${height || '600px'};">`
            const figureOpen = `<figure>`
            const figCaptionOpen = `<figcaption>`
            const figCaption = `Error loading image: ${error}`
            const figCaptionClose = `</figcaption>`
            const figureClose = `</figure>`
            const h3Close = `</h3>`
            
            const src="/imgs/error.png" 
            const alt="Error loading image" 
            const title="Error loading image" 
            width="800" 
            height="600" 
            const style="background-color: ${background || 'white'}; max-width: 100%; height: auto;" 
            const loading="lazy"
            const ImgComponent = getImgComponent(
                src,
                width,
                height,
                title,
                alt,
                null,
                null,
                null,
                background
            );
            const h3Whole = `${h3Open}
                ${figureOpen}
                ${ImgComponent}
                ${figCaptionOpen}
                ${figCaption}
                ${figCaptionClose}
                ${figureClose}
                ${h3Close}`
            return h3Whole;
        }
    });
    return content;
    
}



export async function build_content(content: any, meta?: any,filename?:any): Promise<string> {
  try {
    // Handle synchronous replacements (iframeIt, lyricsIt, imgIt)
    content = iframeIt(content,meta);
    content = lyricsIt(content,meta);
    content = imgIt(content,meta);
    content = seoImgIt(content,meta);
    return content;
  } catch (error) {
    console.error(`Error in build_content for ${filename}: ${error}`);
    return `<p>Error processing content: ${error}</p>`;
  }
}


