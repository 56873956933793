import path from 'path';
import {make_path} from 'shared-functions';
export const tdd_dir = '/var/www/thedailydialectics'
export const tdd_url = 'https://thedailydialectics.com';
export const public_dir = path.join(tdd_dir, 'public');
export const src_dir = path.join(tdd_dir, 'src');
export const contents_dir = path.join(src_dir, 'contents');
export const json_pages_dir = path.join(src_dir,'json_pages');
export const pages_dir = path.join(src_dir,'pages');
export const imgs_dir = path.join(public_dir,'imgs');
export const pdfs_dir = path.join(public_dir,'pdfs');
export const tdd_imgs_url = make_path(tdd_url, 'imgs');
export const tdd_pdfs_url = make_path(tdd_url, 'pdfs');
export const tdd_api_url = make_path(tdd_url, 'api');
export const tdd_valid_pdf_links_url = make_path(tdd_api_url,'valid_pdf_links')
export const tdd_pdf_viewer_info_url = make_path(tdd_api_url,'get_pdf_viewer_info')
export const tdd_metadata_url = make_path(tdd_api_url, 'meta_info');
export const tdd_directory_lists_url = make_path(tdd_api_url, 'directory_lists');
export const tdd_navbar_props_url = make_path(tdd_api_url, 'navbar_props');
export const tdd_directory_path_url = make_path(tdd_api_url, 'directory_path');
export const tdd_directory_link_url = make_path(tdd_api_url, 'directory_link');
export const tdd_directory_count_url = make_path(tdd_api_url, 'directory_count');
export const tdd_source_data_url = make_path(tdd_api_url, 'source_data');
export const tdd_valid_directory_links = make_path(tdd_api_url, 'valid_directory_links');
export const tdd_valid_directory_paths = make_path(tdd_api_url, 'valid_directory_paths');
export const tdd_valid_path_items = make_path(tdd_api_url, 'valid_path_items');
export const tdd_media_url = make_path(tdd_api_url, 'get-media');
export const tdd_image_url = make_path(tdd_api_url, 'get-image-component');
export const tdd_tweet_url = make_path(tdd_api_url, 'get-tweet');
export const tdd_social_post_url = make_path(tdd_api_url, 'get-post-url');
export const all_paths = {
    "imgs":{
        "url":tdd_imgs_url,
        "path":imgs_dir
    },
    "pdfs":{
        "url":tdd_pdfs_url,
        "path":pdfs_dir
    },
    "pages":{
        "url":tdd_url,
        "path":pages_dir
    },
    "json_pages":{
        "url":tdd_url,
        "path":json_pages_dir
    },
    "contents":{
        "url":tdd_url,
        "path":contents_dir
    }
}

/**
 * Returns a full URL.
 * If partial_url is already absolute (starts with http), it is returned as is.
 * Otherwise, it is combined with the base URL.
 */
export function get_full_url(partial_url:any) {
    if (typeof partial_url !== 'string') {
      throw new Error('partial_url must be a string');
    }
    // If it already starts with http, assume it is absolute.
    if (partial_url.startsWith('http')) {
      return partial_url;
    }
    return make_path(tdd_url, partial_url);
  }
  
  /**
   * Returns a full file system path.
   * If partial_path is already absolute, it is returned as is.
   * Otherwise, it is joined with the base directory.
   */
  export function get_full_path(partial_path:any) {
    if (typeof partial_path !== 'string') {
      throw new Error('partial_path must be a string');
    }
    if (path.isAbsolute(partial_path)) {
      return partial_path;
    }
    return path.join(tdd_dir, partial_path);
  }
  
  /**
   * Converts a local file system path into its corresponding URL.
   * It checks against the known directories in all_paths and replaces the matching base.
   */
  export function path_to_url(filePath: string): string | null {
    if (typeof filePath !== 'string') {
      throw new Error('filePath must be a string');
    }
    for (const key in all_paths) {
      const mapping = all_paths[key as keyof typeof all_paths];
      const normalizedBase = path.normalize(mapping.path);
      if (filePath.startsWith(normalizedBase)) {
        const relativePath = filePath.substring(normalizedBase.length);
        return make_path(mapping.url, relativePath.replace(/\\/g, '/'));
      }
    }
    return null;
  }
  
  /**
   * Converts a URL into its corresponding local file system path.
   * It checks against the known URL prefixes in all_paths and replaces the matching base.
   */

export function url_to_path(urlStr: string): string | null {
  if (typeof urlStr !== 'string') {
    throw new Error('urlStr must be a string');
  }
  for (const key in all_paths) {
    const mapping = all_paths[key as keyof typeof all_paths];
    if (urlStr.startsWith(mapping.url)) {
      const relativeUrl = urlStr.substring(mapping.url.length);
      return path.join(mapping.path, relativeUrl);
    }
  }
  return null;
}


